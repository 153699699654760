@import url('https://fonts.googleapis.com/css2?family=Jaro:opsz@6..72&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Jaro:opsz@6..72&family=Teachers:ital,wght@0,400..800;1,400..800&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Jaro:opsz@6..72&family=Poetsen+One&family=Teachers:ital,wght@0,400..800;1,400..800&display=swap');

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  background-image: linear-gradient(45deg, blue, purple);
  min-height: 100vh;
  color: white;
}

.logoText {
  font-family: "Jaro", sans-serif;
  font-size: 40px;
  padding: 30px;
}

nav {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 70px;
  border-bottom: 0.1px solid white;
}

nav ul {
  display: flex;
  font-family: "Teachers", sans-serif;
}

nav ul li {
  list-style: none;
  padding: 30px;
}

nav a {
  color: white;
  text-decoration: none;
}

.toggle {
  display: none;
  padding: 20px;
}

@media (max-width: 1010px) {
  .toggle {
    display: block;
  }

  .liOpen {
    display: block;
  }

  .liClosed {
    display: none;
  }

  .logoText {
    font-size: 32px;
  }

  .logoHidden {
    display: none;
  }

  .menuOpen {
    height: 30vh;
    padding-left: 20px;
    background-image: linear-gradient(90deg, rgb(61, 61, 61), black, rgb(61, 61, 61));
  }

  nav {
    display: flex;
    transition: 0.4s ease;
  }
}  

/* Calculator */

.App h1 {
  padding: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
}

.playerInfo {
  padding: 30px;
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-items: center;
}

.playerInput {
  padding: 15px;
}

.response {
  text-align: center;
  padding: 30px;
}

/* Scoring Page */

.scoringPage {
  height: auto;
}

.scoringPage h1 {
  font-size: 34px;
  text-align: center;
  margin-top: 20px;
}

.playerList {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.scoreListRadio {
  display: flex;
  justify-content: center;
  margin-top: 20px;
}

.playerCard {
  display: flex;
  width: 60%;
  justify-content: space-between;
  border-bottom: 1px solid white;
  align-items: center;
  padding: 30px;

}

.playerInfoAndPic {
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: "Teachers", sans-serif;
}

.playerCard img {
  height: 70px;
  margin: 0px;
}

.playerCard h3 {
  font-size: 40px;
  font-family: "Poetsen One", sans-serif;
}

.playerCard h5 {
  text-align: center;
  color: green;
  font-size: 20px;
}

.scoringPage h6 {
  text-align: center;
}

@media (max-width: 900px) {
  .playerCard {
    width: 70%;
  }

  .playerCard h2 {
    font-size: 25px;

  }

  .playerCard img {
    height: 40px;
  }

  .playerCard h3 {
    font-size: 24px;
  }

  .playerCard h5 {
    font-size: 15px;
  }
}

@media (max-width: 650px) {
  .playerCard {
    width: 80%;
  }

  .playerCard h2 {
    font-size: 20px;

  }

  .playerCard img {
    height: 40px;
  }

  .playerCard h3 {
    font-size: 18px;
  }

  .playerCard h5 {
    font-size: 12px;
  }
}

@media (max-width: 500px) {
  .playerCard {
    width: 85%;
  }

  .playerCard h2 {
    font-size: 20px;
  }

  .playerCard img {
    height: 40px;
  }

  .playerCard h3 {
    font-size: 18px;
  }
}

.empty {
  margin-top: 50px;
}